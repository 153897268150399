<template>
    <div>
        <b-form-select v-model="selected"
                       :options="options"
                       :class="validateError != '' ? 'box-border-color':''"
                       @input="handleInput"
                       :disabled="disabled"></b-form-select>
        <span class="invalid-feedback-custom"
              v-show="validateError"
              v-html="validateError">
        </span>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            default: null
        },
        validateError: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value: function (newValue) {
            this.selected = newValue
        }
    },
    created() {
        this.selected = this.value
    },
    data() {
        return {
            selected: null,
            options: [
                {value: null, text: this.$t('select')},
                {value: 'Female', text: this.$t('female')},
                {value: 'Male', text: this.$t('male')},
                {value: 'Other', text: this.$t('other')}
            ]
        }
    },
    methods: {
        handleInput(event) {
            this.$emit('input', event)
        }
    }
}
</script>
