<template>
    <div>
        <multiselect
            v-model="selected"
            :class="validateError != '' ? 'box-border-color' : ''"
            :clear-on-select="!multiple"
            :close-on-select="!multiple"
            :deselect-label="''"
            :multiple="multiple"
            :options="options"
            :placeholder="$t('select')"
            :select-label="''"
            :selected-label="''"
            label="text"
            track-by="value"
            @input="handleInput($event, multiple)"
        >
            <template slot="selection" slot-scope="{ values, search, isOpen}" v-if="multiple">
                <span v-if="values.length && !isOpen" class="multiselect__single">
	                {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">{{ $t('no_options') }}</span>
            <span slot="noResult">{{ $t('no_result_2') }}</span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>
    // Helpers
    import translateNSelected from '@/helpers/translateNSelected';
    import handleInput from '@/helpers/handleInput';
    import setSelected from '@/helpers/setSelected';

    export default {
        props: {
            value: {
                default: null
            },
            valueType: {
                type: String,
                default: 'id'
            },
            multiple: {
                type: Boolean,
                default: false
            },
            validateError: {
                type: String,
                default: ''
            }
        },
        watch: {
            value: function(newValue) {
                this.selected = this.setSelected(newValue, this.options, this.multiple)
            }
        },
        data() {
            return {
                selected: null,
                options: [
                    {value: 1, text: this.$t('freshman')},
                    {value: 2, text: this.$t('sophomore')},
                    {value: 3, text: this.$t('junior')},
                    {value: 4, text: this.$t('senior')},
                    {value: 5, text: this.$t('graduate')},
                ]
            }
        },
        created() {
            this.selected = this.setSelected(this.value, this.options, this.multiple)
        },
        methods: {
            translateNSelected,
            handleInput,
            setSelected
        }
    }
</script>
